import * as ex from "excalibur";
import { Config } from "@/game/config";
import { makeSnapshotValue, vibrate } from "@/lib/utils";
import { FloatText } from "./float-text";
import { Resources } from "../resources";
import { ItemSpawner, ItemType, SpawnType } from "./item-spawner";
import { playSfxSound } from "@/components/sounds";
import { eventBus } from "@/components/event-bus.ts";
import { playerStoreSelector, setPlayerStore } from "@/components/player-store";
import { captureEvent } from "@/lib/analytics";

export class EnergyBall extends ex.Actor {
  combo: number = 0;
  private random: ex.Random;
  private emitter?: ex.ParticleEmitter;
  private damage: number = 0;
  private maxDamage: number = 50;
  private healthLabel: ex.Label;
  // private helpLabel: ex.Label;
  private energySpawner?: ItemSpawner;
  private energyOnBurst: number = 100;
  private damageAmountPerEnergy: number = 10;
  private idleAdnim: ex.Animation;
  private glowAnim: ex.Animation;
  private releaseAnim: ex.Animation;

  public shadow?: ex.Actor;
  public isTouchingGround: boolean = false;
  public timeOnGround: number = 0;
  #isBursting: boolean = false;

  private squashDuration: number = 100;
  private maxSquashFactor: number = 0.5;
  public isSquashing: boolean = false;
  private originalScale: ex.Vector = new ex.Vector(Config.scale, Config.scale);
  private targetScale: ex.Vector = new ex.Vector(1, 1);
  private squashStartTime: number = 0;
  private maxVelocity: number = 1000;
  private lastClickTime: number = 0;

  constructor(props: {
    x: number;
    y: number;
    health: number;
    maxHealth: number;
  }) {
    super({ x: props.x, y: props.y, radius: 15 });
    this.random = new ex.Random();

    this.body.collisionType = ex.CollisionType.Active;
    this.body.bounciness = 0.7;

    this.damage = props.maxHealth - props.health;
    this.maxDamage = props.maxHealth;

    this.idleAdnim = Resources.EnergyBall.getAnimation("idle")!;
    this.glowAnim = Resources.EnergyBall.getAnimation("glow")!;
    this.glowAnim.strategy = ex.AnimationStrategy.Loop;
    this.glowAnim.events.on("loop", () => {
      this.graphics.use(this.idleAdnim);
    });
    this.releaseAnim = Resources.EnergyBall.getAnimation("release")!;
    this.releaseAnim.strategy = ex.AnimationStrategy.Loop;
    this.releaseAnim.events.on("loop", () => {
      this.graphics.use(this.idleAdnim);
    });

    this.healthLabel = new ex.Label({
      text: `${this.damage}/${this.maxDamage}`,
      pos: ex.vec(props.x, props.y),
      font: new ex.Font({
        family: "Eazy Chat",
        size: 32,
        unit: ex.FontUnit.Px,
        color: ex.Color.White,
        strokeColor: ex.Color.Black,
        lineWidth: 1,
        bold: true,
        textAlign: ex.TextAlign.Center,
        shadow: {
          blur: 1,
          offset: ex.vec(0, 0),
          color: ex.Color.Black,
        },
      }),
    });
    this.healthLabel.graphics.visible = false;
    this.healthLabel.z = 9999;
    // this.helpLabel = new ex.Label({
    //   text: "?",
    //   pos: ex.vec(props.x, props.y),
    //   font: new ex.Font({
    //     family: "Eazy Chat",
    //     size: 24,
    //     unit: ex.FontUnit.Px,
    //     color: ex.Color.fromHex("#E44848"),
    //     strokeColor: ex.Color.Black,
    //     lineWidth: 1,
    //     bold: true,
    //     textAlign: ex.TextAlign.Center,
    //     shadow: {
    //       blur: 1,
    //       offset: ex.vec(0, 0),
    //       color: ex.Color.Black,
    //     },
    //   }),
    // });
    // this.helpLabel.graphics.visible = false;
    // this.helpLabel.z = 9999;
  }

  public onInitialize(engine: ex.Engine): void {
    this.name = "energy-ball";
    let scale = Config.scale;
    this.graphics.use(this.idleAdnim);
    this.scale = ex.vec(scale, scale);
    let viewport = this.scene?.engine.screen.getScreenBounds();
    this.z = viewport!.height - 100;

    this.addEmitter();
    this.scene?.add(this.healthLabel);
    // this.scene?.add(this.helpLabel);

    this.energySpawner = new ItemSpawner(
      SpawnType.Drop,
      ItemType.Energy,
      100,
      new ex.BoundingBox(
        0,
        viewport!.height - 30 * scale,
        viewport!.width,
        viewport!.height - 80,
      ),
      new ex.Vector(this.pos.x, this.pos.y),
    );
    this.scene?.add(this.energySpawner);
    this.energySpawner.collectPoint = ex.vec(
      this.scene?.engine.screen.getScreenBounds().width! - 100,
      60,
    );
    this.energySpawner.spawnDelay = 0;

    this.pointer.useGraphicsBounds = false;
    this.on("pointerdown", (event) => {
      let currenTime = engine.clock.now();
      if (currenTime - this.lastClickTime < 100) {
        return;
      }
      this.handleClick(event);
      this.lastClickTime = currenTime;
    });
    // this.helpLabel.on("pointerdown", (event) => {
    //   eventBus.emit("openEnergyBallExplainerModal");
    //   event.cancel();
    // });
  }

  onPreUpdate(engine: ex.Engine, delta: number): void {
    super.onPreUpdate(engine, delta);

    if (this.isSquashing) {
      const elapsedTime = engine.clock.now() - this.squashStartTime;
      const t = Math.min(elapsedTime / this.squashDuration, 1);

      if (t < 0.5) {
        const easeDT = ex.EasingFunctions.EaseOutQuad(t * 2, 0, 1, 1);
        this.scale = this.originalScale.add(
          this.targetScale.sub(this.originalScale).scale(easeDT),
        );
      } else {
        const easeDT = ex.EasingFunctions.EaseInQuad((t - 0.5) * 2, 0, 1, 1);
        this.scale = this.targetScale.add(
          this.originalScale.sub(this.targetScale).scale(easeDT),
        );
      }

      if (t >= 1) {
        this.isSquashing = false;
        this.scale = this.originalScale;
      }
    }

    if (this.isTouchingGround) {
      this.healthLabel.pos = this.pos.clone();
      // this.helpLabel.pos = ex.vec(
      //   this.healthLabel.pos.x + 50 + 15 * this.damage.toString().length,
      //   this.healthLabel.pos.y,
      // );
      this.timeOnGround += delta;
      if (this.timeOnGround > 200) {
        this.healthLabel.text = `${this.damage}/${this.maxDamage}`;
        this.healthLabel.graphics.visible = true;
        // this.helpLabel.graphics.visible = true;
      }
    } else {
      this.healthLabel.graphics.visible = false;
      // this.helpLabel.graphics.visible = false;
    }
    this.updateShadow();
    this.energySpawner!.target = this.pos.clone();
  }

  public sfxRate() {
    return 0.5 + Math.max(this.combo - 1, 0) / 30;
  }

  public updateShadow() {
    if (this.shadow) {
      this.shadow.pos.x = this.pos.x;
      let distanceY =
        Math.abs(this.shadow.pos.y - (this.pos.y + 8 * Config.scale)) *
        Config.scale;
      let threshold = 512 * Config.scale;
      if (distanceY < threshold) {
        let ratio = 1 - distanceY / threshold;
        this.shadow.scale = ex.vec(
          Config.scale * ratio,
          Config.scale * 0.6 * ratio,
        );
        this.shadow.graphics.opacity = 0.5 * ratio;
      } else {
        this.shadow.graphics.opacity = 0;
      }
    }
  }

  public dealDamage() {
    let prevEnergy = Math.floor(this.damage / this.damageAmountPerEnergy);
    this.damage = Math.min(this.damage + this.combo, this.maxDamage);
    let newEnergy =
      Math.floor(this.damage / this.damageAmountPerEnergy) - prevEnergy;
    //this.actions.scaleBy(ex.vec(0.5, 0.5), 8).scaleBy(ex.vec(-0.5, -0.5), 8);
    if (this.damage === 1) {
      captureEvent("energy_ball_first_hit");
    }
    if (this.damage >= this.maxDamage) {
      captureEvent("energy_ball_burst", {
        // this will be the final combo
        combo: this.combo,
      });
      this.#isBursting = true;
      eventBus.emit("energyBallComboEnded", this.combo);

      newEnergy += this.energyOnBurst;
      this.body.vel = ex.Vector.Zero;
      this.body.acc = ex.Vector.Zero;
      this.body.angularVelocity = 0;
      this.body.torque = 0;
      this.body.collisionType = ex.CollisionType.Fixed;
      let anim = Resources.EnergyBall.getAnimation("burst");
      anim!.strategy = ex.AnimationStrategy.End;
      anim!.speed = 1.5;
      this.graphics.use(anim!);

      anim?.events.on("frame", (frameEvent) => {
        if (frameEvent.frameIndex == 10) {
          this.spawnEnergy(newEnergy);
          playSfxSound("eggExplode");
        }
      });
      anim?.events.on("end", () => {
        this.shadow!.kill();
        this.kill();
      });
    } else if (newEnergy > 0) {
      this.spawnEnergy(newEnergy);
      this.graphics.use(this.releaseAnim);
    } else {
      this.graphics.use(this.glowAnim);
    }
    const currentEnergy = playerStoreSelector.energy();
    setPlayerStore({
      energySnapshot: makeSnapshotValue(currentEnergy + newEnergy),
    });
  }

  public spawnEnergy(newEnergy: number) {
    this.energySpawner!.spawn(newEnergy);
    this.scene?.add(
      new FloatText(
        this.pos.x + this.random.integer(-48, 48),
        this.pos.y - 12 * Config.scale,
        `+${newEnergy} Energy!`,
        32,
        ex.Color.Yellow,
        "Eazy Chat",
      ),
    );
  }

  public resetCombo() {
    this.combo = 0;
  }

  override kill(): void {
    super.kill();
    this.healthLabel.kill();
    // this.helpLabel.kill();
  }

  squash(normal: ex.Vector, velocity: number) {
    if (this.isSquashing) return;
    this.isSquashing = true;
    this.squashStartTime = this.scene!.engine.clock.now();

    const normalizedNormal = normal.normalize();

    const squashDir = new ex.Vector(-normalizedNormal.y, normalizedNormal.x);

    const velocityFactor = Math.min(velocity / this.maxVelocity, 1);
    const squashFactor = this.maxSquashFactor * velocityFactor;

    const elongationFactor = 1 + squashFactor;
    const compressionFactor = 1 / elongationFactor;

    const newScaleX =
      this.originalScale.x *
      (1 +
        (elongationFactor - 1) * squashDir.x * squashDir.x +
        (compressionFactor - 1) * normalizedNormal.x * normalizedNormal.x);
    const newScaleY =
      this.originalScale.y *
      (1 +
        (elongationFactor - 1) * squashDir.y * squashDir.y +
        (compressionFactor - 1) * normalizedNormal.y * normalizedNormal.y);
    this.targetScale = new ex.Vector(newScaleX, newScaleY);

    this.squashDuration = 100 + 100 * (1 - velocityFactor);
  }

  handleClick(event: ex.Input.PointerEvent) {
    if (this.body.sleeping || this.#isBursting) return;
    this.combo += 1;
    this.dealDamage();
    this.body.vel = ex.vec(0, 0);
    this.body.applyLinearImpulse(
      ex.vec(
        this.random.integer(-8000, 8000),
        -5000 * (1 + this.combo * 0.075),
      ),
    );
    vibrate("light");
    playSfxSound("pop", this.sfxRate());
    event.cancel();

    if (this.emitter) {
      this.emitter.pos = event.worldPos.clone();
      this.emitter.isEmitting = true;
      const timer = new ex.Timer({
        fcn: () => {
          if (this.emitter) this.emitter.isEmitting = false;
        },
        repeats: false,
        interval: 200,
      });
      this.scene?.add(timer);
      timer.start();
    }

    this.scene?.add(
      new FloatText(
        this.pos.x + this.random.integer(-32, 32),
        this.pos.y + 12 * Config.scale,
        `x${this.combo}`,
        32,
        ex.Color.Red,
        "Eazy Chat",
      ),
    );
  }

  private addEmitter() {
    const emitterOptions: ex.ParticleEmitterArgs = {
      pos: ex.vec(0, 0),
      width: 0,
      height: 0,
      emitterType: ex.EmitterType.Circle,
      radius: 24 * Config.scale,
      isEmitting: false,
      emitRate: 60,
      z: 1001,
      particle: {
        graphic: Resources.Spark.toSprite(),
        minVel: 30,
        maxVel: 120,
        minAngle: 0,
        maxAngle: 6.2,
        opacity: 1,
        fade: true,
        life: 2000,
        maxSize: 32 * Config.scale,
        minSize: 64,
        startSize: 0,
        endSize: 0,
        acc: ex.vec(0, 250),
        beginColor: ex.Color.White,
        endColor: ex.Color.Transparent,
        angularVelocity: 1000,
        randomRotation: true,
      },
    };

    this.emitter = new ex.ParticleEmitter(emitterOptions);
    (this.emitter.scale = ex.vec(Config.scale, Config.scale)),
      this.scene?.add(this.emitter);
  }
}
